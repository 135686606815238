import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import { ReactComponent as DashIcon } from 'assets/images/svg/dash.svg';
import { ReactComponent as SortIcon } from 'assets/images/svg/sort.svg';
import { clsx } from 'clsx';
import { allowedSorts, allowedSortDirections } from './allowedSorts';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

const SortButton = ({ thisSort, sort, direction, setSort, setDirection }) => {
  const inverseDirection = (direction) => {
    return direction === allowedSortDirections.ASC ? allowedSortDirections.DESC : allowedSortDirections.ASC;
  };

  const handleClick = () => {
    if (sort === thisSort) {
      setDirection(inverseDirection(direction));
    } else {
      setSort(thisSort);
    }
  };

  return <>
    <div
      className={clsx(
        'sort-icon-container',
        sort === thisSort ? 'sort-icon-active' : 'sort-icon-inactive',
        sort === thisSort && (direction === allowedSortDirections.ASC ? 'sort-icon-asc' : 'sort-icon-desc')
      )}
      onClick={handleClick}
    >
      {sort === thisSort ? <DashIcon/> : <SortIcon />}
    </div>
  </>;
};

const UsersTableHeader = ({ sort, direction, setSort, setDirection }) => {
  const columns = [
    {
      title: 'NAME',
      tooltip: 'ENS name or address',
      sort: allowedSorts.NAME,
      sortingAllowed: true
    },
    {
      title: 'PROVIDERS',
      tooltip: 'Connected providers',
      sort: allowedSorts.PROVIDERS,
      sortingAllowed: false
    },
    {
      title: 'RISK SCORE',
      tooltip: 'Average risk score',
      sort: allowedSorts.RISK,
      sortingAllowed: true
    },
    {
      title: 'BALANCE',
      tooltip: 'Aggregated balance',
      sort: allowedSorts.BALANCE,
      sortingAllowed: true
    },
    {
      title: 'ACTIVITY',
      tooltip: 'Last transaction',
      sort: allowedSorts.ACTIVITY,
      sortingAllowed: true
    },
    {
      title: 'SOCIALS',
      tooltip: 'Public social accounts',
      sort: allowedSorts.SOCIALS,
      sortingAllowed: false
    },
    {
      title: 'LOCATION',
      tooltip: 'IP address location',
      sort: allowedSorts.LOCATION,
      sortingAllowed: true
    }
  ];

  return (
    <div className='users-table-row users-table-header'>
      {columns.map(column => (
        <span key={column.title}>
          {column.sortingAllowed &&
            < SortButton
              thisSort={column.sort}
              sort={sort}
              direction={direction}
              setSort={setSort}
              setDirection={setDirection}
            />
          }
          <span>
            {column.title}
          </span>
          <span className='tooltip' data-tooltip={column.tooltip}>
            <InfoIcon/>
          </span>
        </span>
      ))}
    </div>
  );
};

export default UsersTableHeader;
