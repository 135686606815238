import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';

const PlanLimitReachedBox = ({ handleUpgradeButton }) => {
  return (
    <div className='plan-limit-box'>
      <div className='limits-reached'>
        <InfoIcon className='alert-icon'/>
        <span>Usage Limits Reached</span>
      </div>
      <span className='upgrade-text'>Please upgrade your plan for an uninterrupted service.</span>
      <button onClick={handleUpgradeButton} className='upgrade-button'>Upgrade now →</button>
    </div>
  );
};

export default PlanLimitReachedBox;
