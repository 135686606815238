export const SMALLEST_MEDIUM_RISK = 40;
export const SMALLEST_HIGH_RISK = 70;

export const isLowRisk = (riskScore) => riskScore < SMALLEST_MEDIUM_RISK;

export const isMediumRisk = (riskScore) => riskScore < SMALLEST_HIGH_RISK && riskScore >= SMALLEST_MEDIUM_RISK;

export const isHighRisk = (riskScore) => riskScore >= SMALLEST_HIGH_RISK;

export const getLabelClassByRisk = (riskScore) => {
  if (isLowRisk(riskScore)) {
    return 'risk-low';
  } else if (isMediumRisk(riskScore)) {
    return 'risk-medium';
  }
  return 'risk-high';
};

export const getTextClassByRisk = (riskScore) => {
  if (isLowRisk(riskScore)) {
    return 'text-risk-low';
  } else if (isMediumRisk(riskScore)) {
    return 'text-risk-medium';
  }
  return 'text-risk-high';
};
