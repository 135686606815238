import MainButton from 'components/buttons/MainButton';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as AddProjectIcon } from 'assets/images/svg/add-project.svg';
import { ReactComponent as IntegrateIcon } from 'assets/images/svg/integrate.svg';
import { ReactComponent as BuildIcon } from 'assets/images/svg/build.svg';
import { useNavigate, useParams } from 'react-router-dom';

const AnalyticsWelcome = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className='page-content-centered'>
      <div className='content-wrapper'>
        <div className='analytics-icon-container'>
          <AddProjectIcon/>
        </div>
        <p className='analytics-title'>Welcome to your new project</p>
        <p className='analytics-details'>
          Unlock the full potential of your project by importing users and discovering valuable analytics and insights about your user base.
        </p>
      </div>
      <div className='button-wrapper'>
        <MainButton
          variant='button-purple'
          label='IMPORT USERS FROM CSV / CONTRACT'
          icon={<BoltGreen />}
          onClick={() => navigate(`/my-projects/${id}/users`)}
        />
        <a href='https://www.blockmate.io/blockmate-link' target='_blank' rel='noreferrer'>
          <MainButton
            variant='button-purple-light'
            label='INTEGRATE BLOCKMATE LINK'
            icon={<IntegrateIcon />}
          />
        </a>
      </div>
      <a className='analytics-link' href='https://docs.blockmate.io/docs/accounts-service' target='_blank' rel='noreferrer'>
        <BuildIcon/>
        <p className='analytics-link-text'>
          Build custom onboarding solution with API →
        </p>
      </a>
    </div>
  );
};

export default AnalyticsWelcome;
