import { useState, useEffect, useRef } from 'react';
import { ReactComponent as DashIcon } from 'assets/images/svg/dash.svg';
import { clsx } from 'clsx';

const Select = ({ options, placeholder, onOptionClick, selectedIcon, value, disabled, selectClassName }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef(null);

  const renderLabel = (option) => {
    if (option.icon) {
      return (
        <>
          {option.icon}
          {option.label}
        </>
      );
    }

    return option.label;
  };

  const handleOptionClick = (option) => {
    setIsDropdownOpen(false);
    onOptionClick(option);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const toggleSelectOpen = () => {
    if (!disabled) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  return (
    <div className={clsx('select', selectClassName)}>
      <div className={clsx('select-input-container', isDropdownOpen && 'selected', disabled && 'select-disabled')} onClick={() => toggleSelectOpen()}>
        {selectedIcon}
        <input
          value={value}
          placeholder={placeholder}
          className='select-input'
          disabled={disabled}
          readOnly
        />
        <DashIcon className={clsx('dash-icon', isDropdownOpen && 'dash-icon-reverse')}/>
      </div>
      {isDropdownOpen && <div className='select-dropdown'>
        {options.map((option, index) => (
          <span className='dropdown-item' key={index} onClick={() => handleOptionClick(option)}>
            {renderLabel(option)}
          </span>
        ))}
      </div>
      }
    </div>
  );
};

export default Select;
