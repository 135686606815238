import styles from './transaction-risk-categories.module.scss';
import CustomerDetailsBox from '../../pages/customer-details/CustomerDetailsBox';
import { ReactComponent as NineDotsIcon } from '../../assets/images/svg/nine-dots.svg';
import CategoryInfo from './CategoryInfo';
import { useMemo } from 'react';

const allCategories = [
  'Sanctioned Entity',
  'Malicious Address',
  'Suspected Malicious Address',
  'High-risk Tag Address',
  'Medium-risk Tag Address',
  'Mixer',
  'Risk Exchange',
  'Gambling',
  'Involved Theft Activity',
  'Involved Ransom Activity',
  'Involved Phishing Activity',
  'Interact With Malicious Address',
  'Interact With Suspected Malicious Address',
  'Interact With High-risk Tag Address',
  'Interact With Medium-risk Tag Addresses',
];

const labelTypeToCategory = {
  'malicious': 'Interact With Malicious Address',
  'medium_risk': 'Interact With Medium-risk Tag Addresses',
  'high_risk': 'Interact With High-risk Tag Address',
  'suspected_malicious': 'Interact With Suspected Malicious Address',
};

const TransactionRiskCategories = ({ riskResult }) => {
  const interactionDetails = useMemo(() => {
    const riskDetails = riskResult?.risk_detail ?? [];
    const labelByCategory = riskDetails.toReversed().reduce((acc, entry) => {
      if (entry?.type && entry?.label && entry.type in labelTypeToCategory) {
        acc[labelTypeToCategory[entry.type]] = entry.label;
      }
      return acc;
    }, {});
    const categories = riskResult?.detail_list ?? [];
    const customLabels = {};
    categories.forEach(category => customLabels[category] = 'Interaction');
    Object.entries(labelByCategory).forEach(([category, label]) => customLabels[category] = label);
    const isOfacSanctioned = riskDetails.map(entry => entry?.label).some(label => label.includes('OFAC'));
    if (isOfacSanctioned && !categories.includes('Sanctioned Entity')) {
      categories.push('Sanctioned Entity');
    }
    if (isOfacSanctioned) {
      customLabels['Sanctioned Entity'] = 'Interaction (OFAC)';
      customLabels['Interact With Malicious Address'] = 'Interaction';
    }
    return customLabels;
  }, [riskResult]);

  const interactedWithCategories = useMemo(() => Object.keys(interactionDetails), [riskResult]);

  const determineInteraction = (category) => {
    const customLabel = interactionDetails?.[category];
    if (customLabel) {
      return {
        text: customLabel,
        isSafe: false,
      };
    }
    return {
      text: 'No interaction',
      isSafe: true,
    };
  };
  const interactedWithCategoriesSorted = allCategories.filter(category => interactedWithCategories.includes(category));
  const notInteractedWithCategoriesSorted = allCategories.filter(category => !interactedWithCategories.includes(category));
  // There are 2 columns, which are filled in a row-fashion.
  // We want to have interacted-with items in the first column, hence this complicated ordering.
  const orderedCategories = useMemo(() => {
    const ordered = [];
    interactedWithCategoriesSorted.forEach((category, index) => ordered[index * 2] = category);
    notInteractedWithCategoriesSorted.forEach((category, index) => ordered[index * 2 + 1] = category);
    return ordered.filter(category => category);
  }, [interactedWithCategories]);

  return <CustomerDetailsBox title='Transactions risk categories' icon={<NineDotsIcon />}>
    <div className={styles.categoriesContainer}>
      {orderedCategories.map((category, index) =>
        <CategoryInfo
          title={category}
          valueInfo={determineInteraction(category)}
          withBorder={index < allCategories.length - 2}
          key={category}
        />
      )}
    </div>
  </CustomerDetailsBox>;
};

export default TransactionRiskCategories;
