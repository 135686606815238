export const getChainByAddress = (address) => {
  const chainRegexps = [
    {
      ticker: 'btc',
      regex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$/,
      label: 'Bitcoin'
    },
    {
      ticker: 'eth',
      regex: /^0x[a-fA-F0-9]{40}$/,
      label: 'Ethereum'
    },
    {
      ticker: 'trx',
      regex: /^T[A-Za-z1-9]{33}$/,
      label: 'Tron'
    },
    {
      ticker: 'ada',
      regex: /^addr1[a-zA-HJ-NP-Z0-9]{25,99}$/,
      label: 'Cardano'
    },
    {
      ticker: 'bch',
      regex: /^((bitcoincash|bchreg|bchtest):)?(q|p)[a-z0-9]{41}$/,
      label: 'Bitcoin Cash'
    },
    {
      ticker: 'flow',
      regex: /^0x[a-fA-F0-9]{16}$/,
      label: 'Flow'
    },
    {
      ticker: 'ltc',
      regex: /^([LM3]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|ltc1[a-z0-9]{39,59})$/,
      label: 'Litecoin'
    },
    {
      ticker: 'near',
      regex: /^(([a-z\\d]+[\\-_])*[a-z\\d]+\\.)*([a-z\\d]+[\\-_])*[a-z\\d]+$/,
      label: 'Near'
    },
    {
      ticker: 'neo',
      regex: /^N[0-9a-zA-Z]{33}$/,
      label: 'Neo'
    },
    {
      ticker: 'sol',
      regex: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
      label: 'Solana'
    },
    {
      ticker: 'xlm',
      regex: /^G[A-Z2-7]{55}$/,
      label: 'Stellar'
    },
    {
      ticker: 'xrp',
      regex: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/,
      label: 'Ripple'
    }
  ];
  const possibleChains = chainRegexps
    .filter(chainInfo => chainInfo.regex.test(address))
    .map(chainInfo => ({
      ticker: chainInfo.ticker,
      label: chainInfo.label
    }));
  return possibleChains[0];
};
