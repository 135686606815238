import { useState } from 'react';
import ToggleSwitch from '../toggle-switch/ToggleSwitch';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import { getPlanPriceByPeriod } from '../../utils/billing/utils';
import { clsx } from 'clsx';

const SaveBadge = ({ active, saveAmount }) => {
  return (
    <div className={`save-badge ${active ? 'save-badge-active' : 'save-badge-inactive'}`}>
      SAVE ${saveAmount}
    </div>
  );
};

const PlanSelectorHeading = ({ plans, showFrequencyToggle = true, onFrequencyChange = (_) => {} }) => {
  const [frequency, setFrequency] = useState('monthly');
  const isFrequencyToggleToggled = frequency === 'monthly';

  const saveAmount = plans.map((plan) => {
    const monthlyPeriodYearlyPrice = getPlanPriceByPeriod(plan, 'month') * 12;
    const annualPeriodYearlyPrice = getPlanPriceByPeriod(plan, 'year');
    if (!plan.contact_only && typeof monthlyPeriodYearlyPrice === 'number' && typeof annualPeriodYearlyPrice === 'number') {
      return Math.floor(monthlyPeriodYearlyPrice - annualPeriodYearlyPrice);
    }
    return 0;
  }).reduce((maximum, current) => Math.max(maximum, current), 0);

  const toggleFrequency = () => {
    const newFrequency = frequency === 'monthly' ? 'annually' : 'monthly';
    setFrequency(newFrequency);
    onFrequencyChange(newFrequency);
  };

  return (<div className='plan-selector-heading-container'>
    <div className={clsx('account-plan-heading-title', !showFrequencyToggle && 'centered-horizontal')}>
      ACCOUNT PLAN
    </div>
    {showFrequencyToggle && <div className='frequency-toggle-container'>
      <div className={`centered-vertical ${isFrequencyToggleToggled ? 'selected' : 'unselected'}`}>
        Monthly
      </div>
      <div className={clsx('frequency-toggle-checkbox-container', isFrequencyToggleToggled && 'upside-down')}>
        <ToggleSwitch isToggled={true} onToggle={toggleFrequency}/>
      </div>
      <div className={`centered-vertical ${isFrequencyToggleToggled ? 'unselected' : 'selected'}`}>
        Annually
      </div>
      <div className='save-badge-container centered-vertical'>
        <SaveBadge active={!isFrequencyToggleToggled} saveAmount={saveAmount}/>
      </div>
      <div className='frequency-tooltip-container centered-vertical'>
        <span className='tooltip' data-tooltip='Save by selecting the yearly plan'>
          <InfoIcon/>
        </span>
      </div>
    </div>}
  </div>);
};

export default PlanSelectorHeading;
