import appRoutes from 'routes/routes.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'assets/images/svg/calendar.svg';
import ArrowLeft from 'assets/images/svg/arrow-left.svg';
import { ReactComponent as BlockmateLogo } from 'assets/images/svg/blockmate-logo.svg';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';

const Header = ({
  headerTitle,
  withBackButton,
  backRoute,
  backRouteIfMissingHistory, // If backRoute is not provided, we try to go back in history. If it is not available, use this.
  withNotification,
  isLimitReached,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = appRoutes;

  const goBack = () => {
    if (backRoute) {
      navigate(backRoute);
    } else if (location.key !== 'default') { // Something is in history, go there
      navigate(-1);
    } else if (backRouteIfMissingHistory) {
      navigate(backRouteIfMissingHistory);
    } else {
      navigate(home.analytics);
    }
  };

  return (
    <>
      <div className='header-container'>
        <div className='mobile-header-logo-container' onClick={() => navigate(home.dashboard)}>
          <BlockmateLogo/>
        </div>
        <div className='header-breadcrumbs'>
          {withBackButton && <div onClick={goBack} className='header-back-icon'>←</div>}
          <div className='header-title'>
            <span>{headerTitle}</span>
          </div>
        </div>
        {withNotification && (
          <div className='header-addon'>
            <img src={Calendar} alt='calendar icon'/>
            <span>System Maintenance • 11/25 • 1am CET</span>
            <img src={ArrowLeft} alt='arrow-icon' className='header-arrow-left'/>
          </div>
        )}
        {isLimitReached && (
          <div className='header-addon limit' onClick={() => navigate(home.billingManagePlan)}>
            <InfoIcon className='alert-icon'/>
            <span>You&apos;ve reached plan&apos;s usage limits. Upgrade plan →</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
