import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

const UserButton = ({
  firstIconSrc,
  secondIconSrc,
  firstIconAlt,
  secondIconAlt,
  children,
  onClick
}) => {
  const { pathname } = useLocation();

  return (
    <button className={clsx('menu-user-button', pathname === '/my-account' && 'active')}
      onClick={onClick}>
      <img src={firstIconSrc} alt={firstIconAlt}/>
      <span className='user-button-description'>{children}</span>
      <img src={secondIconSrc} alt={secondIconAlt} className='user-button-dashes'/>
    </button>
  );
};

export default UserButton;
