import axios from 'axios';
import { endpoints } from '../services/api/axios';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import appRoutes from '../routes/routes';
import { UserContext } from '../context/user/UserContext';
import { useContext } from 'react';

export const useRoleBasedRedirect = () => {
  const { getConfig } = useContext(UserContext);
  const navigate = useNavigate();

  const roleBasedRedirect = () => {
    if (getConfig('my_projects')) {
      navigate(appRoutes.home.myProjects);
    } else if (getConfig('users_management')) {
      navigate(appRoutes.home.usersManagement);
    } else if (getConfig('explorer')) {
      navigate(appRoutes.home.explorer);
    } else if (getConfig('aml_quick_check')) {
      navigate(appRoutes.home.amlQuickCheck);
    } else {
      const excludedProjectNames = ['Explorer'];
      const namesByPriority = ['AML POC', 'AML'];
      axios.get(endpoints.PROJECT_URL)
        .then((response) => {
          let hasExplorer = response.data
            .filter(project => project.name === 'Explorer').length;
          let amlEmpty = response.data
            .filter(project => project.name === 'AML Empty');

          if (amlEmpty.length > 0) {
            if (hasExplorer) {
              navigate(appRoutes.home.explorer);
              return;
            } else {
              toast.error('Redirect problem');
            }
          }

          let filteredProjects = response.data
            .filter(project => !excludedProjectNames.includes(project.name))
            .sort((a, b) => {
              for (const name of namesByPriority) {
                if (a.name === name) {
                  return -1;
                }
                if (b.name === name) {
                  return 1;
                }
              }
              if (a.demo) {
                return 1;
              }
              if (b.demo) {
                return -1;
              }
            });
          if (!getConfig('show_demo_project')) {
            filteredProjects = filteredProjects.filter((project) => !project.demo);
          }

          if (filteredProjects.length > 0) {
            navigate(generatePath(getConfig('default_project_tab'), { id: filteredProjects[0].id }));
          } else {
            toast.error('No projects available');
          }
        });
    }
  };

  return roleBasedRedirect;
};
