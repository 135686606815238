import { NavLink } from 'react-router-dom';
import MenuButton from 'components/buttons/menu-button/MenuButton.jsx';
import { ReactComponent as ListIcon } from 'assets/images/svg/list-icon.svg';
import { ReactComponent as BillingIcon } from 'assets/images/svg/billing.svg';
import { ReactComponent as UsersIcon } from 'assets/images/svg/users.svg';
import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';
import { ReactComponent as SearchQuickIcon } from 'assets/images/svg/search-quick.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/svg/settings.svg';
import { ReactComponent as HomeIcon } from 'assets/images/svg/home-button.svg';
import appRoutes from 'routes/routes.js';
import { UserContext } from '../../context/user/UserContext';
import { useContext } from 'react';

const DashboardSidebar = () => {
  const { home } = appRoutes;
  const { getConfig } = useContext(UserContext);

  return (
    <>
      {getConfig('dashboard') && <NavLink to={home.dashboard} className='tooltip' data-tooltip='Dashboard'>
        <MenuButton icon={<HomeIcon/>}>
          {getConfig('is_aml_demo') ? 'Home' : 'Dashboard'}
        </MenuButton>
      </NavLink>}
      {getConfig('aml_quick_check') && <NavLink to={home.amlQuickCheck} className='tooltip' data-tooltip='AML Quick Check'>
        <MenuButton icon={<SearchQuickIcon/>}>
          AML Quick Check
        </MenuButton>
      </NavLink>}
      {getConfig('explorer') && <NavLink to={home.explorer} className='tooltip' data-tooltip='AML Explorer'>
        <MenuButton icon={<SearchIcon/>}>
          AML Explorer
        </MenuButton>
      </NavLink>}
      {getConfig('my_projects') && <NavLink to={home.myProjects} className='tooltip' data-tooltip='My Projects'>
        <MenuButton icon={<ListIcon/>}>
          My Projects
        </MenuButton>
      </NavLink>}
      {!getConfig('is_aml_demo') && <NavLink to={home.billing}>
        <MenuButton icon={<BillingIcon/>}>
          Billing
        </MenuButton>
      </NavLink>}
      {getConfig('users_management') && <NavLink to={home.usersManagement}>
        <MenuButton icon={<UsersIcon/>}>
          Users
        </MenuButton>
      </NavLink>}
      {getConfig('project_settings') && <NavLink to={home.companySettings}>
        <MenuButton icon={<SettingsIcon/>}>
          Settings
        </MenuButton>
      </NavLink>}
    </>
  );
};

export default DashboardSidebar;
