import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import ChangePeriodSelect from 'components/widget/ChangePeriodSelect';

const ChartWrapper = ({
  title,
  children,
  name,
  tooltipContent,
  withoutPeriod
}) => {
  return (
    <div className='chart-wrapper'>
      <div className='chart-header'>
        <div className='chart-title'>{title}</div>
        <div className='chart-settings'>
          <ChangePeriodSelect
            name={name}
            withoutPeriod={withoutPeriod}
          />
          <div className='info'>
            <span className='tooltip' data-tooltip={tooltipContent}>
              <InfoIcon />
            </span>
          </div>
        </div>
      </div>
      <div className='chart-content'>
        {children}
      </div>
    </div>
  );
};

export default ChartWrapper;
