import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import styles from '../../pages/customer-details/style.module.scss';
import useNotification from '../notification/UseNotification';
import { ReactComponent as TabIcon } from '../../assets/images/svg/tab.svg';
import CopyToClipboardTooltip from '../copy-to-clipboard-tooltip/CopyToClipboardTooltip';
import { clsx } from 'clsx';

export const CopyToClipboardWithIcon = ({ children, copyValue, tooltipDisplayedData = undefined }) => {
  const showNotification = useNotification();

  return <div>
    <CopyToClipboardTooltip tooltipData={tooltipDisplayedData} openOnHover>
      <CopyToClipboard text={copyValue} onCopy={() => showNotification('copyData', 'success')}>
        <div className={styles.copyChildrenContainer}>
          <div className={clsx(styles.icon, styles.smallCopyIcon)}><TabIcon/></div>
          <div className={styles.labeledAddressContainer}>
            {children}
          </div>
        </div>
      </CopyToClipboard>
    </CopyToClipboardTooltip>
  </div>;
};

export default CopyToClipboardWithIcon;
