import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getStaticPath } from 'services/api/axios';

const WidgetBox = ({
  icon,
  cardTitle,
  isToggle,
  name,
  tooltipContent,
}) => {
  const [toggled, setToggled] = useState(isToggle);
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();

  const handleToggle = async () => {
    setIsDisabled(true);
    const res = await axios.post(
      getStaticPath('STATISTICS_URL', 'widget'),
      {
        name,
        timeframe: '90d',
        visible: !toggled
      },
      { params: { project_id: id } }
    );

    if (res) {
      setToggled(!toggled);
      setIsDisabled(false);
    }
  };

  return (
    <div className='widget-box'>
      <div className='widget-icon-box black-icon'>
        {icon}
        <p className='widget-name'>
          {cardTitle}
        </p>
      </div>
      <div className='toggle-switch-container'>
        <ToggleSwitch isToggled={toggled} onToggle={handleToggle} isDisabled={isDisabled}/>
        <span className='tooltip' data-tooltip={tooltipContent}>
          <InfoIcon />
        </span>
      </div>
    </div>
  );
};

export default WidgetBox;
