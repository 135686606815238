import { ReactComponent as UsersSecondary } from 'assets/images/svg/users-secondary.svg';
import { ReactComponent as ImportIcon } from 'assets/images/svg/import.svg';
import { ReactComponent as FileIcon } from 'assets/images/svg/file.svg';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/svg/upload-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/images/svg/info-icon.svg';
import MainButton from 'components/buttons/MainButton';
import MainModal from 'components/modals/MainModal';
import { useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getStaticPath } from 'services/api/axios';
import { toast } from 'react-hot-toast';
import AnalyticsUpload from 'components/analytics-views/AnalyticsUpload';
import debounce from 'lodash/debounce';
import Select from 'components/select/Select';
import { ReactComponent as Ethereum } from 'assets/images/svg/providers/ethereum.svg';
import LinkButtonWithIcon from 'components/buttons/LinkButtonWithIcon';
import useNotification from 'components/notification/UseNotification';
import { clsx } from 'clsx';

const MODAL_STEP = {
  FORM: 'form',
  PENDING_INFO_CSV: 'pending info csv',
  PENDING_INFO_Contract: 'pending info contract'
};

const providerOptions = [
  { label: 'Ethereum', icon: <Ethereum/> }
];

const UsersModal = ({ handleClose, setIsContractImported, isContractImported, usersLength, isLoading }) => {
  const { id } = useParams();
  const [file, setFile] = useState();
  const [error, setError] = useState(null);
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const hiddenInput = useRef(null);
  const [modalStep, setModalStep] = useState(MODAL_STEP.FORM);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [countCheckStatusFunction, setCountCheckStatusFunction] = useState(0);
  const [selectedOption, setSelectedOption] = useState(providerOptions[0]);
  const [contractAddress, setContractAddress] = useState();
  const showNotification = useNotification();

  const acceptedFileTypes = ['text/csv', 'application/vnd.ms-excel'];

  const handleUpload = () => {
    if (contractAddress) {
      contractUpload();
    }
    else {
      fileUpload();
    }
  };

  const fileUpload = async () => {
    formData.append('import_file', file);
    try {
      await axios.post(getStaticPath('USERLIST_URL', `${id}/import`), formData);
      showNotification('uploadCsv', 'success');
      setModalStep(MODAL_STEP.PENDING_INFO_CSV);
    } catch (e) {
      showNotification('uploadCsv', 'error');
    }
  };

  const contractUpload = async () => {
    try {
      await axios.post(getStaticPath('USERLIST_URL', `${id}/import-contract`), {
        contract: contractAddress
      });
      toast.success('Uploaded successfully');
      setModalStep(MODAL_STEP.PENDING_INFO_Contract);
      setIsContractImported(true);
      setError(null);
    } catch (e) {
      toast.error('Something went wrong');
      setError('Wrong contract address');
    }
  };

  const handleOnChange = (event) => {
    setError(null);
    event.preventDefault();
    setLoading(true);
    const fileReader = new FileReader();

    if (!event.target.files[0]) {
      setLoading(false);
      return;
    }

    const { type } = event.target.files[0];
    if (!acceptedFileTypes.includes(type)) {
      setError('Only CSV supported');
      setLoading(false);
      return;
    }

    fileReader.onload = function () {
      setFile(event.target.files[0]);
      setLoading(false);
    };
    fileReader.readAsText(event.target.files[0]);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setError(null);
    const { type } = event.dataTransfer.files[0];
    if (!acceptedFileTypes.includes(type)) {
      setError('Only CSV supported');
      setLoading(false);
      return;
    }
    setFile(event.dataTransfer.files[0]);
  };

  const handleClick = () => {
    hiddenInput.current.click();
  };

  const handleRemove = (event) => {
    event.stopPropagation();
    setFile(null);
    hiddenInput.current.value = null;
  };

  const handleExport = async () => {
    setIsDownloadInProgress(true);
    try {
      const res = await axios.post(getStaticPath('USERLIST_URL', `${id}/export`));
      const taskId = res.data;
      await debouncedCheckTaskStatus(taskId);
    } catch (e) {
      showNotification('exportCsv', 'error');
    }
  };

  const debouncedCheckTaskStatus = debounce(async (taskId) => {
    if (countCheckStatusFunction < 6) {
      try {
        const res = await axios.get(getStaticPath('USERLIST_URL', `task-status/${taskId}`));
        const file_id = res.data.result.file;
        if (res.data.status === 'SUCCESS') {
          await downloadFile(file_id);
        }
      } catch (e) {
        debouncedCheckTaskStatus(taskId);
      }
      setCountCheckStatusFunction(countCheckStatusFunction + 1);
      setIsDownloadInProgress(false);
    } else {
      showNotification('exportCsv', 'error');
    }
  }, 3000);

  const downloadFile = async (file_id) => {
    try {
      const res = await axios({
        url: getStaticPath('USERLIST_URL', `download/${file_id}`),
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file_id}`);
      document.body.appendChild(link);
      link.click();

    } catch (e) {
      showNotification('exportCsv', 'error');
    }
    setIsDownloadInProgress(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleAddressOnChange = (e) => {
    setContractAddress(e.target.value);
  };

  return (
    <MainModal handleClose={handleClose}>
      {modalStep === MODAL_STEP.FORM &&
            <div className='modal-content users-modal'>
              <div className='modal-icon-container'>
                <span className='modal-icon'>
                  <UsersSecondary/>
                </span>
                <span className='users-modal-title'>
                  Import users
                </span>
              </div>
              <div className='import-section-container'>
                <div className='import-by-contract-container'>
                  <div className='import-method-title'>
                    <span>Import from a smart contract</span>
                    <span className='tooltip' data-tooltip='One-time import per project'><InfoIcon/></span>
                  </div>
                  <Select value={selectedOption.label} options={providerOptions} placeholder={'Choose a provider'} onOptionClick={handleOptionClick} selectedIcon={selectedOption.icon} disabled={true}/>
                  <input className='users-list-input' placeholder='Smart contract address' type='text' onChange={handleAddressOnChange} disabled={file || isContractImported}/>
                </div>
                <div className='divider'>
                </div>
                <div className='import-by-csv-container'>
                  <div className='import-method-title'>
                    <span>Import from a CSV file</span>
                    <span className='tooltip' data-tooltip='Unlimited imports per project'><InfoIcon/></span>
                  </div>
                  <form onSubmit={handleUpload} className='file-input'>
                    <div
                      onDrop={handleFileDrop}
                      onDragOver={(event) => event.preventDefault()}
                      className={clsx('file-input-drop-zone', (contractAddress || isContractImported) && 'dropzone-disabled')}
                      onClick={handleClick}
                    >
                      <UploadIcon/>
                      {file ? ( <div className='file-input-filename'>
                        <span className='file-input-label'>{file.name}</span>
                        <span className='file-input-delete-icon' onClick={handleRemove}>&times;</span>
                      </div> ) : (
                        <>
                          <span className='file-input-label'>Drop .csv here or&nbsp;</span>
                          <span className='file-input-link'>click to upload</span>
                        </>
                      )}
                    </div>
                    <input
                      className='file-input-input'
                      type='file'
                      onChange={handleOnChange}
                      ref={hiddenInput}
                      accept='.csv'
                      disabled={contractAddress || isContractImported}
                    />
                  </form>
                </div>
              </div>
              <div className='file-input-error'>{error}</div>
              <MainButton
                variant='button-purple'
                label='IMPORT USERS'
                icon={<BoltGreen/>}
                onClick={e => handleUpload(e)}
                loading={loading}
                disabled={!contractAddress && !file && !loading }
              />
              <div className='export-buttons-container'>
                <LinkButtonWithIcon
                  label='Export all user data as CSV file'
                  loading={isDownloadInProgress || isLoading}
                  disabled={usersLength === 0}
                  icon={<ImportIcon/>}
                  onClick={handleExport}
                />
                <a className='export-csv' download='csv-template.csv' href='/csv-template.csv'>
                  <FileIcon/>
                  <span className='export-csv-text'>Download .csv template</span>
                </a>
              </div>
            </div>
      }
      {modalStep === MODAL_STEP.PENDING_INFO_CSV && <AnalyticsUpload importTitle={'CSV file was successfully uploaded!'}/>}
      {modalStep === MODAL_STEP.PENDING_INFO_Contract && <AnalyticsUpload importTitle={'Contract address was successfully uploaded!'}/>}
    </MainModal>
  );
};

export default UsersModal;
