import { determineChainFromTx } from '../billing/transactions';
import { CRYPTO, determineCurrencyType } from '../sidebar/fiatCurrencies';
import { ellipsis, valueExists } from '../common';


const FROM = 'from';
const TO = 'to';

export const determineTxType = (transaction, concise = false) => {
  let type = transaction?.type;
  const isDeposit = type.includes('deposit');
  const isWithdrawal = type.includes('withdraw');
  if (concise) {
    type = type.includes('fiat') ? 'fiat' : 'crypto';
  }
  return {
    type,
    isDeposit,
    isWithdrawal
  };
};

export const getTxTypeDescription = (transactionType) => {
  const descriptions = {
    'crypto deposit': 'Deposit of a cryptocurrency to the account.',
    'crypto withdrawal': 'Withdrawal of a cryptocurrency from the account.',
    'fiat deposit': 'Deposit of a fiat currency to the account.',
    'fiat withdrawal': 'Withdrawal of a fiat currency from the account.',
    'transfer': 'Transfer of funds between accounts. This is a generic type used if a more specific one is not available.',
    'internal trade': 'Trade between two assets on the same exchange.',
    'internal swap': 'Swap between two assets on the same exchange.',
    'trade': 'Trade between two assets on the same exchange.',
    'interest': 'Withdrawal of interest from staking on the exchange.',
    'staking earn payout': 'Payout of staking rewards.'
  };
  const description = descriptions[String(transactionType).toLowerCase()];
  if (description) {
    return description;
  }
  return transactionType;
};

export const determineInstitutionInfo = (transaction, accountInfo, providersInfo) => {
  if (accountInfo?.providerType === 'onchain') {
    return `OnChain / ${accountInfo.provider}`;
  }
  // The provider is an exchange, so we determine if the tx is onchain (crypto deposit or crypto withdrawal)
  if (['crypto deposit', 'crypto withdrawal'].includes(String(transaction?.type).toLowerCase())) {
    const chain = determineChainFromTx(transaction, providersInfo);
    return chain ? `OnChain / ${String(chain).toLowerCase()}` : null;
  }
  // The provider is an exchange, so we determine if the tx is a fiat deposit or fiat withdrawal
  if (['fiat deposit', 'fiat withdrawal'].includes(String(transaction.type).toLowerCase())) {
    return transaction?.description ? `Bank / ${transaction.description}` : 'Bank';
  }
  // The tx is an internal swap on an exchange
  return accountInfo?.provider ? `Exchange / ${accountInfo.provider}` : null;
};

export const determineProviders = (transaction) => {
  const possibleProviders = [...transaction.inputs.reduce((acc, input) => {
    const currency = input.currency_symbol.toLowerCase();
    acc.add(`${determineCurrencyType(input.currency_symbol)} / ${ellipsis(currency, 8)}`);
    return acc;
  }, new Set())];
  // It can either contain fiats or cryptos. If some token has a fiat ticker, it will be detected due to crypto fees
  const presentCryptoProviders = possibleProviders.filter(provider => provider.startsWith(CRYPTO));
  if (presentCryptoProviders.length > 0) {
    return presentCryptoProviders;
  }
  return possibleProviders; // Pure fiats in this case
};

export const movementSortFn = (a, b) => {
  if (a.owned) {
    return -1;
  }
  if (b.owned) {
    return 1;
  }
  if (a.category && b.category) {
    return a.risk > b.risk ? -1 : 1;
  }
  return a.category ? -1 : 1;
};

const sumOwnMovements = (movements) => {
  const ownMovements = movements.filter(movement => movement.owned);
  return ownMovements.reduce((acc, movement) => {
    if (!movement.currency) {
      return acc;
    }
    if (!Object.keys(acc).includes(movement.currency)) {
      const currencySymbol = valueExists(movement.currency_symbol)
        ? movement.currency_symbol
        : movement.currency.split(':')[1] ?? '?';
      acc[movement.currency] = {
        currencySymbol,
        value: 0,
        convertedValue: 0,
        side: FROM
      };
    }
    acc[movement.currency].value += movement.value;
    acc[movement.currency].convertedValue += (movement.converted_value ?? 0);
    acc[movement.currency].side = acc[movement.currency].value < 0 ? FROM : TO;
    return acc;
  }, {});
};

const subtractAggregatedCurrencies = (a, b) => {
  const result = { ...a };
  Object.keys(b).forEach(currency => {
    if (!result[currency]) {
      result[currency] = {
        currencySymbol: b[currency].currencySymbol,
        value: 0,
        convertedValue: 0,
        side: FROM
      };
    }
    result[currency].value -= b[currency].value;
    result[currency].convertedValue -= b[currency].convertedValue;
    result[currency].side = result[currency].value < 0 ? FROM : TO;
  });
  return result;
};

export const calculateDelta = (transaction) => {
  const ownInputSum = sumOwnMovements(transaction.inputs);
  const ownOutputSum = sumOwnMovements(transaction.outputs);
  const delta = subtractAggregatedCurrencies(ownOutputSum, ownInputSum);
  const deltaValues = Object.values(delta).filter(currencyInfo => currencyInfo.value !== 0);
  const getCurrenciesStrBySide = (side) => deltaValues
    .filter(currencyInfo => currencyInfo.side === side)
    .map(currencyInfo => currencyInfo.currencySymbol)
    .join(', ');
  const fromCurrencies = getCurrenciesStrBySide(FROM);
  const toCurrencies = getCurrenciesStrBySide(TO);
  let prefixTransferSymbol = '';
  let infixTransferSymbol = '';
  let maxLength = 24;
  if (valueExists(fromCurrencies) && valueExists(toCurrencies)) {
    infixTransferSymbol = '→';
    maxLength = 12; // Each side will have 12 characters at most displayed
  } else if (!valueExists(fromCurrencies)) {
    prefixTransferSymbol = '+';
  } else if (!valueExists(toCurrencies)) {
    prefixTransferSymbol = '-';
  }
  return {
    delta: deltaValues.slice(0, 1),
    fromCurrencies: getCurrenciesStrBySide(FROM),
    toCurrencies: getCurrenciesStrBySide(TO),
    prefixTransferSymbol,
    infixTransferSymbol,
    maxLength
  };
};
