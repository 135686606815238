import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const MainModal = ({ children, handleClose }) => {
  return (
    <div className='modal-page-container' onClick={handleClose}>
      <div className='modal-container' onClick={e => e.stopPropagation()}>
        <div className='modal-close-button' onClick={handleClose}>
          <CloseIcon className='close-icon' />
        </div>
        {children}
      </div>
    </div>
  );
};

export default MainModal;
