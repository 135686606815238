import styles from './style.module.scss';
import ConnectedAccounts from './ConnectedAccounts';
import ConsolidatedTransactions from './ConsolidatedTransactions';
import { useOutletContext, useParams } from 'react-router-dom';
import RiskScoreOverview from './RiskScoreOverview';
import AccountOverview from './AccountOverview';
import axios from 'axios';
import { endpoints } from '../../services/api/axios';
import { useEffect, useState, useContext } from 'react';
import handleApiError from '../../services/ErrorHandleUtil';
import { OLD_TABLES_KEY } from '../debug/debug';
import { UserContext } from '../../context/user/UserContext';

const CustomerDetails = () => {
  const [projectJWT, setProjectJWT] = useState(undefined);
  const [userJWT, setUserJWT] = useState(undefined);
  const [customerDetails, setCustomerDetail] = useState(undefined);
  const [balanceData, setBalanceData] = useState(undefined);
  const [closedLoopData, setClosedLoopData] = useState(undefined);
  const { project } = useOutletContext();
  const params = useParams();
  const { getConfig } = useContext(UserContext);
  const userId = params.user_id;
  const projectId = params.id;
  const oldTables = localStorage.getItem(OLD_TABLES_KEY) === 'ON';

  useEffect( () => {
    (async () => {
      try {
        let response = await axios.get(`${endpoints.APIAUTH_URL}/project/${projectId}`);
        setProjectJWT(response.data.token);
      } catch (error) {
        setProjectJWT(undefined);
        handleApiError('getting project jwt', error);
      }
    })();
  }, [projectId]);

  useEffect(() => {
    (async () => {
      try {
        let response = await axios.get(`${endpoints.APIAUTH_URL}/user/${projectId}/${userId}`);
        setUserJWT(response.data.token);
      } catch (error) {
        setUserJWT(undefined);
        handleApiError('getting user jwt', error);
      }
    })();
  }, [projectId, userId]);

  useEffect(() => {
    (async () => {
      if (projectJWT !== undefined && project.currency !== undefined) {
        try {
          let response = await axios.get(`/v1/analytics/project/dashboardUsers/${userId}?currency=${project.currency}`,
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': 'Bearer ' + projectJWT,
              }
            });
          setCustomerDetail(response.data);
        } catch (error) {
          setCustomerDetail(undefined);
          handleApiError('getting customer data', error);
        }
      }
    })();
  }, [project, projectJWT, userId]);

  useEffect(() => {
    (async () => {
      if (userJWT !== undefined && project.currency !== undefined) {
        try {
          let response = await axios.get(`/v1/aggregate/balance?currency=${project.currency}`,
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': 'Bearer ' + userJWT,
              }
            });
          setBalanceData(response.data);
        } catch (error) {
          setBalanceData(undefined);
          handleApiError('getting balance', error);
        }
      }
    })();
  }, [project, userJWT]);

  useEffect(() => {
    (async () => {
      if (userJWT !== undefined) {
        try {
          // ?debug=1 will be removed after closedloop was verified
          let response = await axios.get('/v1/aggregate/closedLoop?debug=1',
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': 'Bearer ' + userJWT,
              }
            });
          setClosedLoopData(response.data);
        } catch (error) {
          setClosedLoopData(undefined);
          handleApiError('getting closedLoop info', error);
        }
      }
    })();
  }, [userJWT]);

  return (
    // Do not change the id of this div, it is used for taking screenshots.
    <div className={styles.customers} id='customer-details-div'>
      <AccountOverview balanceData={balanceData} customerDetails={customerDetails}/>
      <RiskScoreOverview customerDetails={customerDetails} closedLoop={closedLoopData}/>
      <ConnectedAccounts
        balanceData={balanceData}
        customerDetails={customerDetails}
        expandable={!oldTables}
        currency={project.currency}
      />
      <ConsolidatedTransactions
        customerName={customerDetails?.name ?? ''}
        balanceData={balanceData}
        userJWT={userJWT}
        concise={!oldTables}
        currency={project.currency}
        userId={userId}
        projectId={projectId}
        withFilters
      />
    </div>
  );
};

export default CustomerDetails;
