import dayjs from 'dayjs';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleString('en-US', options);
};

export const dateObjectToDateString = (date, includeTime = false) => {
  if (includeTime) {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return dayjs(date).format('YYYY-MM-DD');
  }
};

export const getDateStringsRange = (fromDate, toDate) => {
  const dateDifference = dayjs(toDate).diff(fromDate, 'day');
  const allWantedDates = [...Array(dateDifference)].map((_, index) =>
    dayjs(fromDate).add(index, 'day').format('YYYY-MM-DD')
  );
  return allWantedDates;
};

export const formatDateStrAsShortMonthNoYear = (dateStr) => {
  return dayjs(dateStr).format('MMM DD');
};

export const dateStrOrTimestampToTimestamp = (dateStrOrTimestamp) => {
  if (typeof dateStrOrTimestamp === 'number') {
    return dateStrOrTimestamp;
  }
  return new Date(dateStrOrTimestamp ?? 0).getTime() / 1000;
};
