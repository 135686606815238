import { useState, useEffect, useRef, useContext } from 'react';
import MenuButton from 'components/buttons/menu-button/MenuButton';
import UserButton from 'components/buttons/menu-user-button/UserButton';
import { ReactComponent as BlockmateLogoWithName } from 'assets/images/svg/blockmate-logo.svg';
import { ReactComponent as BlockmateLogoWithoutName } from 'assets/images/svg/blockmate-logo-icon.svg';
import { ReactComponent as Dash } from 'assets/images/svg/dash.svg';
import Avatar from 'assets/images/svg/avatar.svg';
import SettingsDots from 'assets/images/svg/settings-dots.svg';
import appRoutes from 'routes/routes.js';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from 'context/user/UserContext';
import HamburgerMenu from 'components/mobile/hamburger-menu/HamburgerMenu';
import { clsx } from 'clsx';
import SidebarAlert from './sidebar-alert/SidebarAlert';

const Sidebar = ({ children, canGoHome }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { home } = appRoutes;
  const profileSettingsRef = useRef(null);
  const menuRef = useRef(null);
  const { performLogout, userEmail, isLimitReached, getConfig } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (profileSettingsRef.current && !profileSettingsRef.current.contains(event.target)) {
      setIsSettingsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutsideSidebar = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      if (event.target.classList.contains('hamburger-line')) {
        return;
      }
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSidebar, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar, true);
    };
  },[isMobileView]);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);

  return (
    <>
      <HamburgerMenu handleHamburgerClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} isMobileMenuOpen={isMobileMenuOpen}/>
      <div className={clsx('menu-container', isCollapsed && 'collapsed', isMobileMenuOpen && 'mobile-menu-open')} ref={menuRef}>
        <Link to={canGoHome && home.dashboard} className='menu-logo-container'>
          {isCollapsed ?
            <BlockmateLogoWithoutName/>
            :
            <BlockmateLogoWithName/>
          }
        </Link>
        <div className='menu-buttons-container'>
          {children}
          <div className='tooltip' data-tooltip={clsx(isCollapsed && 'Open menu')}>
            <MenuButton
              icon={<Dash className='menu-dash'/>}
              alt='Dash icon'
              className='dash-icon-button'
              onClick={toggleMenu}
              imageClassName='menu-dash'
            >
              Close Menu
            </MenuButton>
          </div>
        </div>
        <div className='profile-button-container'>
          {isLimitReached && <SidebarAlert handleAlertClick={() => navigate(home.billing)}/>}
          {isSettingsOpen && <div ref={profileSettingsRef} className='settings-popup'>
            {!getConfig('is_aml_demo') && <div>
              <Link to={home.myAccount} onClick={() => setIsSettingsOpen(false)}>
                <p className='settings-my-account'>My account</p>
              </Link>
              <div className='divider'/>
            </div>}
            <p className='settings-log-out' onClick={() => performLogout()}>Log out</p>
          </div>
          }
          <UserButton firstIconSrc={Avatar} secondIconSrc={SettingsDots} firstIconAlt='Avatar icon'
            secondIconAlt='Three dots - settings icon'
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
            {userEmail}
          </UserButton>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
