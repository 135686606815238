import { FaCheck } from 'react-icons/fa';
import { EditText } from 'react-edit-text';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as SettingsDots } from 'assets/images/svg/settings-dots.svg';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as LockClosed } from 'assets/images/svg/lock-closed.svg';
import { ReactComponent as LockOpened } from 'assets/images/svg/lock-opened.svg';
import { ReactComponent as Info } from 'assets/images/svg/info-icon.svg';
import { ReactComponent as Bookmark } from 'assets/images/svg/bookmark.svg';
import apiTokenService from 'services/api-tokens-services';
import { formatDate } from 'utils/date-formatter';
import ApiKeysCounter from 'components/counter/Counter';
import { useParams } from 'react-router-dom';
import useNotification from 'components/notification/UseNotification';
import { clsx } from 'clsx';

const ApiKeysTableRow = ({ token, displayApiKey, index, activeTokens, displayCopyBtn, copiedApiToken, apiToken, handleCopyKey, handleDeleteKey }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const ref = useRef(null);
  const [apiKeyName, setApiKeyName] = useState('');
  const { updateToken } = apiTokenService;
  const { id } = useParams();
  const showNotification = useNotification();

  const handleChangeKeyName = (e, token_id) => {
    setApiKeyName((prev) => ({
      ...prev,
      [token_id]: e.target.value
    }));
  };

  const handleSaveKeyName = async (e, project_id, token_id) => {
    try {
      await updateToken(project_id, token_id, e.value);
      showNotification('changeApiKeyName', 'success');
    } catch (error) {
      setApiKeyName((prev) => ({
        ...prev,
        [token_id]: e.previousValue
      }));
      showNotification('changeApiKeyName', 'error');
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsDeleteOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className='api-keys-table-row'>
      <span className='key-name'>
        <div className='key-icon'>
          {displayCopyBtn(index, activeTokens.length) ? (
            <LockOpened className='api-keys-table-icon'/>
          ) : (
            <LockClosed className='api-keys-table-icon'/>
          )}
        </div>
        <div className='input-wrapper'>
          <EditText
            onChange={(e) => handleChangeKeyName(e, token.id)}
            onSave={(e) =>
              handleSaveKeyName(e, id, token?.id)
            }
            value={apiKeyName[token.id] ?? token?.name}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          />
        </div>
      </span>
      <div className='value-container'>
        <p className='value'>
          {displayApiKey(index, activeTokens.length)}
        </p>
      </div>
      <span className='timer-container'>
        {displayCopyBtn(index, activeTokens.length) ? (
          <>
            <CopyToClipboard
              text={apiToken?.token ?? ''}
              onCopy={() => handleCopyKey(token?.id)}
            >
              <div className='copy-container'>
                <ApiKeysCounter />
                <div className='copy-icon-container'>
                  {copiedApiToken === token.id
                    ? <FaCheck className='api-keys-table-icon-copied'/>
                    : (
                      <Bookmark/>
                    )}
                </div>
              </div>
            </CopyToClipboard>
          </>
        ) : (
          <div className='copy-container'>
            <span className='copy-box'>HIDDEN</span>
            <Info/>
          </div>
        )}
      </span>
      <span className='last-used-at'>
        {token.lastused_at?
          (formatDate(token.lastused_at)
          ) : (
            <span>No information</span>
          )}
      </span>
      <span className='created-at'>
        {token.created_at?
          (formatDate(token.created_at)
          ) : (
            <span>No information</span>
          )}
      </span>
      <span className='expiration'>No Expiration</span>
      <span className='delete' onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
        <SettingsDots/>
        <div ref={ref} className={clsx('delete-popup', isDeleteOpen && 'delete-open')}>
          <span onClick={() => handleDeleteKey(id, token?.id)}>
						Delete key
          </span>
        </div>
      </span>
    </div>
  );
};

export default ApiKeysTableRow;
